
  .gd_text{
    color: transparent;
    background: linear-gradient(
      to right,
      rgb(245,173, 125)0%,
      rgb(255,246,231)50%,
      rgb(245,173, 125)80%
    );
    -webkit-background-clip: text;
    background-clip: text;

  }
  main{
    font-size: 1em;
    
    padding: 30px;
    border-radius: 10px;
  
    
  }
  td {
    padding: 5px;
  }
  tbody tr {
    border-radius: 8px;

  
    background-color: #0000000d;
  }
  tbody tr:hover {
    outline: 1px solid blue;
  }
  .join-btn {        
    background: linear-gradient(
      to right,
      rgb(245,173, 125)0%,
      rgb(255,246,231)50%,
      rgb(245,173, 125)80%
    );

    color: black;
    font-size: 0.8em;
    border: none;
    padding: 9px;
    border-radius: 6px;
    width: 90px;
    cursor: pointer;
  }
  .delete-btn{
    background-color: transparent;
    width: 20px;
    height: 30px;
    border-radius: 10px;
    cursor: pointer;
    transition: 150ms ease-in-out;
    
    pointer-events: all;
  }
 
@media (max-width: 500px) {
  main{
    width: 100%;
    font-size: 0.6em;
  }
  
}
h1 {
  text-align: center;
}
ul {
  list-style: none;
  padding: 0;
}
li {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
main {
  height: 100%;
  max-height: unset;
  width: 95%;
  max-width: 600px;
}
input {
  font-size: 1.2em;
  width: 80%;
}
form {
  width: 100%;
  display: flex;
  justify-content: center;
}
#chat-window {
  
  max-width: 900px;
  width: 100%;
  color: black;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
button {
  font-size: 1em;
  background-color: rgb(56 110 255);
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  width: 20%;
}
#messages {
  height: 80%;
  overflow: auto;
  padding: 5px;
  box-sizing: border-box;
  
}
.gd_text {
  color: transparent;
  background: linear-gradient(
    to right,
    rgb(245, 173, 125) 0%,
    rgb(255, 246, 231) 50%,
    rgb(245, 173, 125) 80%
  );
  -webkit-background-clip: text;
  background-clip: text;
  overflow-wrap: anywhere;
}

@media screen and (max-width: 600px) {
  .messages,
  button,
  input {
    font-size: 1em;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  width: 100dvw;
  height: 100dvh;
}
#root{
  width: 100%;
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
}
header{
  width: 100%;
}
header nav{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 0;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ccc;
}
header nav a{
  text-decoration: none;
  color: #333;
  font-size: 1.5em;
  font-weight: bold;
  padding: 10px;
}
header nav a:hover{
  color: #f5f5f5;
  background-color: #333;
  border-radius: 5px;
}
header nav a.active{
  color: #f5f5f5;
  background-color: #333;
  border-radius: 5px;
}
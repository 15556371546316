.App {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex
;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}

::-webkit-scrollbar{
  width: 5px;
}
::-webkit-scrollbar-thumb{
  background-color: black;
  border-radius: 10px;
}
::-webkit-scrollbar-track{
  background-color: white;
}
.gd_text {
  color: transparent;
  background: linear-gradient(
    to right,
    rgb(245, 173, 125) 0%,
    rgb(255, 246, 231) 50%,
    rgb(245, 173, 125) 80%
  );
  -webkit-background-clip: text;
  background-clip: text;
}
main {
  font-size: 1em;

  padding: 0;
  border-radius: 10px;
  height: 90%;

  overflow: hidden;
  max-height: 500px;
}
table {
  border-collapse: collapse;
  max-height: 500px;
  overflow: auto;
  /* height: 500px; */
  display: block;
  padding: 5px
}
td {
  padding: 5px;
}
tbody tr {
  border-radius: 8px;

  background-color: transparent;
}
tbody tr:hover {
  outline: 1px solid blue;
}
.join-btn {
  background: linear-gradient(
    to right,
    rgb(245, 173, 125) 0%,
    rgb(255, 246, 231) 50%,
    rgb(245, 173, 125) 80%
  );

  color: black;
  font-size: 0.8em;
  border: none;
  padding: 9px;
  border-radius: 6px;
  width: 90px;
  cursor: pointer;
}

